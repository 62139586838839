import { IPortalAppSdk } from '../../../CommonLibrary/models/portal-parent-sdk';
import { ensureTrailing } from './string';
export * from '../../../CommonLibrary/models/portal-parent-sdk';
export * from '../../../CommonLibrary/modules/auth/auth0';

export function parentEventBus() {
  return (window as any).eftEventBus as Vue;
}

export function inParentApp() {
  return parentEventBus() != null || (window as any).usermanagement.isInParent;
}

export const APP_TAG = 'user-management' as const;
const APP_ROUTE_BASE = '/admin/user-management/';

export function appRouteBase() {
  return inParentApp() ? APP_ROUTE_BASE : '/';
}

export function findAppScriptSourceOrigin() {
  const el = document.getElementById(`${APP_TAG}-script`) as HTMLScriptElement;
  let url = el == null ? '' : new URL(el.src).origin;
  url = ensureTrailing(url, '/');
  return url;
}

export function getParentAppSdk(): IPortalAppSdk {
  return document.getElementById(APP_TAG) as any;
}
